import React from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css';
import HomePage from "./pages/HomePage";
import Layout from "./layout/Layout";
import AboutPage from "./pages/AboutPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<HomePage />} />
        <Route path="aprops" element={<AboutPage />} />
      </Route>
    </Routes>
  );
}

export default App;
