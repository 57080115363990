import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import rpsReference from "../assets/img/slider-1/rps-reference.jpg";
import digitalPrinting from "../assets/img/slider-1/imprission-numerique.png";
import slider1 from "../assets/img/slider-1/1.jpg";
import slider2 from "../assets/img/slider-1/2.jpg";
import slider3 from "../assets/img/slider-1/3.jpg";

export default function Slider() {
  return (
    <>
      <OwlCarousel items={1} className="owl-theme" loop dots={false}>
        <div>
          <img className="img" src={rpsReference}/>
          {/*<div className="title-container s-tb-c title-compress">*/}
          {/*  <h1 className="title1"><span>High Quality</span> Materials</h1>*/}
          {/*  <div className="details-content">*/}
          {/*    <p>Dorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod teididunt ut labore et orem*/}
          {/*      ipsum</p>*/}
          {/*    <p> dolor sit ameo eiusmod tm do eiusmod teididunt ut labore et dolore.</p>*/}
          {/*  </div>*/}
          {/*  <div className="read-more">*/}
          {/*    <ul>*/}
          {/*      <li><a href="#">Read More <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></a></li>*/}
          {/*      <li><a href="#">Contact Us <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></a></li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div><img className="img" src={digitalPrinting}/></div>
        <div><img className="img" src={rpsReference}/></div>
      </OwlCarousel>
    </>
  )
}