import React from 'react';
import OwlCarousel from "react-owl-carousel";
import lazrakLogo from "../assets/img/brand/lazrak.png";
import ColasLogo from "../assets/img/brand/Colas_logo.png";
import emEnergie from "../assets/img/brand/emEnergie.png";
import hiolle from "../assets/img/brand/hiolle.png";
import Exprom from "../assets/img/brand/Exprom.png";
import LPF from "../assets/img/brand/LPF.png";

export default function BrandHome() {
  return (
    <>
      <div className="brand-area">
        <div className="container">
          <OwlCarousel items={6} dots={false} margin={20} className="brand kon-carousel owl-theme">
            <div className="brand-box">
              <img className="img-responsive" src={lazrakLogo} alt="Lazrak"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={ColasLogo} alt="colas"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={emEnergie} alt="emEnergie"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={hiolle} alt="hiolle"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={Exprom} alt="Exprom"/>
            </div>
            <div className="brand-box">
              <img className="img-responsive" src={LPF} alt="LPF"/>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}